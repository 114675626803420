import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['container']

  connect() {
  }

  show() {
    this.containerTarget.classList.add('overlayed')
    document.dispatchEvent(new Event('overlay:shown'))
  }
  showAndFocusInput() {
    this.show()
    document.dispatchEvent(new Event('overlay::input:focused'))
  }


  hide() {
    this.containerTarget.classList.remove('overlayed')
  }
}
