import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['positiveValue', 'positiveLink', 'negativeValue', 'negativeLink']

  votePositive() {
    this.positiveLinkTarget.classList.add('active')
    this.negativeLinkTarget.classList.remove('active')
  }
  voteNegative() {
    this.negativeLinkTarget.classList.add('active')
    this.positiveLinkTarget.classList.remove('active')
  }

  complete(event) {
    const json = JSON.parse(event.detail[0].response)
    this.positiveValueTarget.innerHTML = json.positive
    this.negativeValueTarget.innerHTML = json.negative
  }

}