import { Controller } from "stimulus"
import debounce from 'lodash.debounce'

export default class extends Controller {
  static targets = ['input', 'results']

  connect() {
    this.element['controller'] = this
    this.inputTarget.setAttribute('autocomplete', 'off')
    this.inputTarget.setAttribute('spellcheck', 'false')

    this.onInputChange = debounce(this.onInputChange.bind(this), 500)

    this.inputTarget.addEventListener('input', this.onInputChange)
    document.addEventListener('overlay::input:focused', this.onFocus.bind(this))
  }

  disconnect() {
    this.inputTarget.removeEventListener('input', this.onInputChange)
  }
  onFocus() {
    this.inputTarget.focus()
  }

  onInputChange() {
    this.fetchResults()
  }

  fetchResults() {
    const query = this.inputTarget.value.trim()

    if (!query || query.length < 2) {
      return
    }

    const url = new URL(this.data.get('url') , window.location.href)
    const params = new URLSearchParams()
    params.append('query', query)
    url.search = params.toString()

    fetch(url.toString())
      .then(response => response.text())
      .then(html => {
        this.resultsTarget.innerHTML = html
      })
      .catch(() => {
      })
  }
}
