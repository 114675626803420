import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['footer', 'form', 'text', 'parentId']

  showForm(event) {
    event.target.parentNode.append(this.formTarget)
    this.textTarget.focus()
    this.parentIdTarget.value = event.target.dataset.parentId
  }

  hideForm() {
    this.formTarget.reset()
    this.footerTarget.append(this.formTarget)

  }

  formWasSubmitted() {
    this.hideForm()
    alert(this.element.dataset.successText)
  }
}
