import { Controller } from "stimulus"
import StarRating from 'star-rating.js'

export default class extends Controller {
  static targets = ['select']

  connect() {
    this.selectTargets.forEach((selectTarget) => {
      new StarRating(selectTarget, { initialText: 'Оцените от 1 до 5'})

    })
  }

}
